<template>
  <div class="relative">
    <div class="my-8 justify-between flex mx-2">
      <div class="">
        <h2 class="font-bold text-2xl">Payroll</h2>
        <p class="text-md">Pengaturan Payroll</p>
      </div>
      <div class="">
        <p>
          <label for="payroll">Tanggal Payroll</label>
        </p>
        <vc-date-picker
          mode="date"
          v-model="date"
          :model-config="{
            type: 'string',
            mask: 'YYYY-MM'
          }"
          :masks="{ input: 'YYYY-MM' }"
          :max-date="new Date()"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="bg-primary bg-opacity-5 border-b border-primary px-2 py-1 max-w-32"
              name="payroll"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </vc-date-picker>
      </div>
    </div>
    <div class="border border-primary mt-16 mx-2">
      <div class="flex gap-2 border-b items-center border-primary w-full">
        <button class="bg-primary whitespace-nowrap text-white px-4 py-2 font-bold" @click="print">
          <v-icon icon="material-symbols:print" class="text-2xl mr-1 inline" />
          Cetak
        </button>
        <div class="text-right w-full pr-4">Tekan tombol cetak untuk mencetak slip gaji</div>
      </div>
      <PayrollComponent ref="previewPDF" :key="date" :date="date" />
    </div>
  </div>
</template>

<script>
  import PayrollComponent from "@/components/PayrollComponent.vue";
  import html2pdf from "html2pdf.js";

  export default {
    name: "PayrollPage",
    components: {
      PayrollComponent
    },
    data() {
      return {
        date: new Date()
      };
    },
    methods: {
      print() {
        const element = this.$refs.previewPDF.$el;
        html2pdf(element, {
          margin: 1,
          filename: "payroll.pdf",
          jsPDF: {
            unit: "in",
            format: "A4",
            orientation: "portrait"
          }
        });
      }
    }
  };
</script>
