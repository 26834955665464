<template>
  <div class="relative">
    <van-popup v-model="toggle" round position="bottom">
      <CameraComp
        v-if="toggle"
        @photo="
          (a) => {
            picture.picture = a;
            toggle = false;
          }
        "
      />
    </van-popup>

    <van-cell-group inset :title="title || 'Ambil Foto'">
      <van-cell title="Buka Kamera" @click="() => (toggle = true)" is-link label="Tekan untuk mengambil foto" />
      <van-cell v-if="picture.picture">
        <template>
          <div class="flex my-2 gap-2">
            <van-image @click="openPreview" :src="pictureUrl" width="7rem" height="7rem" fit="contain" />
            <van-field
              v-model="picture.description"
              rows="3"
              autosize
              type="textarea"
              maxlength="50"
              placeholder="Deskripsi foto (opsional)"
              show-word-limit
            />
          </div>
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
  import CameraComp from "@/components/Inputs/CameraComp.vue";
  import { ImagePreview } from "vant";

  export default {
    components: {
      CameraComp
    },
    props: {
      title: String
    },
    data() {
      return {
        toggle: false,
        picture: {
          picture: null,
          description: ""
        }
      };
    },
    emits: ["selected"],
    watch: {
      picture: {
        handler(val) {
          this.$emit("selected", val);
        },
        immediate: true,
        deep: true
      }
    },
    computed: {
      pictureUrl() {
        if (!this.picture.picture) return null;
        return URL.createObjectURL(this.picture.picture);
      }
    },
    methods: {
      takePhoto(photo) {
        const url = URL.createObjectURL(photo);
        this.pictures.push(url);
      },
      openPreview() {
        ImagePreview({ images: [this.pictureUrl], closeable: true });
      }
    }
  };
</script>
