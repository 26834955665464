<template>
  <div id="app" class="mx-auto max-w-xl relative min-h-screen">
    <router-view></router-view>
  </div>
</template>

<script>
  import { location } from "@/states/location";
  import { Dialog } from "vant";
  export default {
    name: "App",
    data: () => ({
      location
    }),
    mounted() {
      if (this.location.isMocked)
        Dialog.alert({
          title: "Akun anda dikunci",
          message: "Anda melanggar aturan penggunaan aplikasi. Harap hubungi IT untuk informasi lebih lanjut.",
          confirmButtonText: "Dimengerti."
        }).then(() => {
          localStorage.removeItem("token");
          this.$router.push("/");
        });
    },
    watch: {
      location: {
        handler() {
          if (this.location.isMocked)
            Dialog.alert({
              title: "Akun anda dikunci",
              message: "Anda melanggar aturan penggunaan aplikasi. Harap hubungi IT untuk informasi lebih lanjut.",
              confirmButtonText: "Dimengerti."
            }).then(() => {
              localStorage.removeItem("token");
              this.$router.push("/");
            });
        },
        deep: true
      }
    },
    methods: {
      logout() {
        localStorage.removeItem("token");
        this.$router.push("/login");
      }
    }
  };
</script>

<!-- bentuk nya sama kayak laporan harian, +lokasi aja -->
