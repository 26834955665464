// eslint-disable-next-line no-unused-vars
import { ref, watch, computed } from "vue";
import { useOnline } from "@vueuse/core";
import lzstring from "lz-string";
import instance, { fileUpload } from "@/plugins/axios";

const STORAGE_KEYS = {
  QUEUE: "uploadQueue",
  FILES: "fileData"
};

const convertBase64ToBlob = (base64Data) => {
  const [metadata, base64] = base64Data.split(",");
  const mimeType = metadata.split(":")[1].split(";")[0];
  const binary = atob(base64);
  const array = new Uint8Array(binary.length);
  for (let i = 0; i < binary.length; i++) {
    array[i] = binary.charCodeAt(i);
  }
  return new Blob([array], { type: mimeType });
};

const convertBlobToBase64 = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result);
  });
};

export const online = useOnline();

const dataQueue = ref([]);
const dataFiles = ref([]);
export const isLoading = ref(false);

const loadStorage = () => {
  const queueData = JSON.parse(lzstring.decompress(window.localStorage.getItem(STORAGE_KEYS.QUEUE)) || "[]");
  const filesData = JSON.parse(lzstring.decompress(window.localStorage.getItem(STORAGE_KEYS.FILES)) || "[]");

  dataQueue.value.push(...queueData);
  dataFiles.value.push(...filesData);
};
loadStorage();

const synchronizeStorage = async () => {
  console.log("Synchronizing...", dataQueue.value);
  const compress = lzstring.compress(JSON.stringify(dataQueue.value));
  const files = lzstring.compress(JSON.stringify(dataFiles.value));
  window.localStorage.setItem(STORAGE_KEYS.QUEUE, compress);
  window.localStorage.setItem(STORAGE_KEYS.FILES, files);
};

const saveFileOffline = (file) => {
  return new Promise((resolve) => {
    const id = Math.round(Math.random() * 1e16).toString(36);
    convertBlobToBase64(file).then((base64) => {
      dataFiles.value.push({ i: id, d: base64 });
      resolve(id);
    });
  });
};

const processFile = (file, forceSave = false) => {
  return new Promise((resolve, reject) => {
    if (!online.value || forceSave)
      return saveFileOffline(file)
        .then((id) => {
          console.log(id);
          resolve(id);
        })
        .catch((error) => reject(error));
    fileUpload(file)
      .then((response) => resolve(response))
      .catch((error) => {
        console.error("Error uploading file:", error);

        if (
          error.toJSON().message === "Network Error" ||
          error.response?.status === 502 ||
          error.response?.status === 530
        )
          processFile(file, true).then(resolve).catch(reject);
      });
  });
};

const processData = async (url, data, forceSave = false) => {
  return new Promise((resolve, reject) => {
    if (!online.value || forceSave) {
      dataQueue.value.push({ u: url, d: data, t: Date.now() });
      return resolve({ offline: true });
    }
    instance
      .post(url, data)
      .then((response) => resolve(response))
      .catch((error) => {
        // if offline error

        console.log(error, error.toJSON());
        if (
          error.toJSON().message === "Network Error" ||
          error.response?.status === 502 ||
          error.response?.status === 530
        ) {
          return processData(url, data, true).then(resolve).catch(reject);
        }

        reject(error);
      });
  });
};

const uploadQueue = async () => {
  // const currentQueue = [...dataQueue.value]; // Copy to avoid modifying while iterating
  // for (const [index, { u, d }] of currentQueue.entries()) {
  isLoading.value = true;
  while (dataQueue.value.length > 0) {
    const { u, d, t } = dataQueue.value[0];
    try {
      if (d.images) {
        for (const id of d.images) {
          const file = dataFiles.value.find((f) => f.i === id);
          if (file) {
            const uploaded = await fileUpload(convertBase64ToBlob(file.d)).catch((error) => {
              console.error("Error uploading file:", error);
              return null;
            });

            if (uploaded) {
              // Replace the ID with the uploaded file URL
              d.images[d.images.indexOf(id)] = uploaded;
              dataFiles.value.splice(dataFiles.value.indexOf(file), 1);
            }
          }
        }
      }

      // Submit the data
      await instance.post(u, { ...d, startTimestamp: t }).then(() => {
        dataQueue.value.splice(0, 1); // Remove processed item
        console.log("Processed queue item:", u, d, 0, "Remaining:", dataQueue.value);
      });
    } catch (error) {
      console.error("Error processing queue item:", error);
      // Do not remove the item if it failed; retry later
    }
  }
  isLoading.value = false;

  synchronizeStorage(); // Synchronize after processing
};

watch(
  () => online.value,
  (isOnline) => {
    if (isOnline) uploadQueue();
    else synchronizeStorage();
  }
);

export const attendanceList = computed(() => dataQueue.value.filter((item) => item.u.includes("attendance")));

export const dailyReportList = computed(() => dataQueue.value.filter((item) => item.u.includes("daily")));

export const patrolReportList = computed(() => dataQueue.value.filter((item) => item.u.includes("patrol")));

export const overtimeList = computed(() => dataQueue.value.filter((item) => item.u.includes("overtime")));

watch(() => dataQueue.value, synchronizeStorage, { deep: true });
watch(() => dataFiles.value, synchronizeStorage, { deep: true });

export default {
  dataQueue,
  dataFiles,
  loadStorage,
  synchronizeStorage,
  saveFileOffline,
  processFile,
  processData,
  uploadQueue
};
