<template>
  <div class="py-4">
    <div class="p-2">
      <router-link to="/dashboard/report/cleaning/new" class="bg-primary px-4 py-2 rounded flex float-right">
        <v-icon icon="material-symbols:add" class="text-2xl text-white mr-2" />
        <span class="text-white">Tambah Laporan</span>
      </router-link>
      <h1 class="text-2xl font-bold">Laporan Harian</h1>
      <p>Laporan harian yang telah dibuat oleh pengguna.</p>
    </div>
    <template v-for="date in dailyReports">
      <DropDown :key="date.date">
        <template #title>
          <span class="text-left">{{ date.date }}</span>
        </template>
        <template #default>
          <template v-for="report in date.reports">
            <router-link
              :to="`/dashboard/report/cleaning/${report.id}`"
              class="p-2 border-b border-gray-200 flex hover:bg-secondary hover:bg-opacity-25 duration-150"
              :key="report.id"
            >
              <div class="w-12 h-12 rounded bg-primary mr-2 text-white px-1 font-bold text-sm">
                <p>{{ iconParse(report.timestamp) }}</p>
              </div>
              <div>
                <h3 class="text-lg font-semibold">{{ report.title }}</h3>
                <p class="text-sm">{{ report.description }}</p>
              </div>
              <v-icon icon="material-symbols:chevron-right" class="text-2xl ml-auto my-auto" />
            </router-link>
          </template>
        </template>
      </DropDown>
    </template>
  </div>
</template>
<script>
  import DropDown from "@/components/DropDown.vue";
  import dayjs from "dayjs";

  export default {
    data() {
      return {
        reports: [
          {
            id: 1,
            title: "Daily Report",
            description: "Generate daily report.",
            timestamp: new Date(2024, 6, 1).valueOf()
          },
          {
            id: 12,
            title: "Daily Report",
            description: "Generate daily report.",
            timestamp: new Date().valueOf()
          }
        ]
      };
    },
    components: {
      DropDown
    },
    computed: {
      dailyReports() {
        return this.sortReportsByTimestamp();
      }
    },
    methods: {
      sortReportsByTimestamp() {
        let dailyReport = [];
        this.reports
          .sort((a, b) => new Date(b.timestamp).valueOf() - new Date(a.timestamp).valueOf())
          .forEach((report) => {
            const reportDate = dayjs(report.timestamp).format("YYYY-MM-DD");
            const existingReport = dailyReport.find((r) => r.date === reportDate);
            if (existingReport) {
              existingReport.reports.push(report);
            } else {
              dailyReport.push({ date: reportDate, reports: [report] });
            }
          });
        return dailyReport;
      },
      iconParse(date) {
        const day = dayjs(date).format("HH:mm");
        return day;
      }
    }
  };
</script>
