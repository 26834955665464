<template>
  <div v-if="containQueue" class="animate_warning shadow-md rounded text-black mx-4 h-64 mb-8 relative">
    <!-- <div>
      <van-button
        size="small"
        type="info"
        loading-type="spinner"
        @click="offlineSync"
        class="whitespace-nowrap text-md mt-1 float-right"
      >
        <v-icon icon="material-symbols:sync" class="text-xl mr-1 inline" />
        <span class="ml-1 font-bold">Synchronize</span>
      </van-button>
      <h3 class="font-semibold text-lg">Kamu mempunyai data offline</h3>
      <div>
        <p class="text-sm">Kamu mempunyai {{ containQueue }} data offline</p>
      </div>
    </div> -->

    <div class="w-full h-full absolute flex justify-center items-center">
      <div class="">
        <p class="text-center text-xl mb-4 font-semibold">Kamu Mempunyai {{ containQueue }} Data Offline!</p>
        <p class="text-center text-sm">
          {{ isLoading && "Tunggu sebentar..." }}
        </p>
        <van-button
          type="info"
          loading-type="spinner"
          :disabled="isLoading"
          @click="offlineSync"
          class="whitespace-nowrap text-2xl mt-1 px-24 py-12 w-full rounded-lg shadow-md"
        >
          <v-icon icon="material-symbols:sync" class="text-xl mr-1 inline" />
          <span class="ml-1 font-bold uppercase">Synchronize</span>
        </van-button>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .animate_warning {
    animation: warning 1s infinite step-start;
  }

  @keyframes warning {
    0% {
      background-color: #f6f6f6;
    }
    50% {
      background-color: #ff0000;
      color: #fff;
      /* transform: scale(1.5); */
    }
    100% {
      background-color: #f6f6f6;
    }
  }
</style>

<script>
  import local, { attendanceList, isLoading } from "@/states/local";

  export default {
    data() {
      return { attendanceList, dataQueue: local.dataQueue, isLoading };
    },
    methods: {
      offlineSync() {
        local.uploadQueue();
      }
    },
    computed: {
      containQueue() {
        return this.dataQueue.length;
      }
    }
  };
</script>
