<template>
  <div>ssdfdgfhgjhkjlk</div>
</template>

<script>
  export default {
    methods: {
      pushNotification() {}
    }
  };
</script>
