<template>
  <div class="py-4">
    <div class="p-2">
      <router-link to="/dashboard/report/daily/new" class="bg-primary px-4 py-2 rounded flex float-right">
        <v-icon icon="material-symbols:add" class="text-2xl text-white mr-2" />
        <span class="text-white">Buat</span>
      </router-link>
      <h1 class="text-2xl font-bold">Laporan Harian</h1>
      <p>Laporan harian yang telah dibuat oleh pengguna.</p>
    </div>

    <van-collapse v-model="activeAccordion" v-if="online">
      <van-collapse-item v-for="date in dailyReports" :key="date.date">
        <template #title>
          <span class="text-left">{{ date.date }}</span>
        </template>
        <template #default>
          <van-list>
            <van-cell v-for="report in date.reports" :key="report.id">
              <template>
                <div class="w-full">
                  <h3>
                    <van-tag type="primary">
                      <p>{{ iconParse(report.timestamp) }}</p>
                    </van-tag>
                    <span class="text-lg font-semibold">
                      {{ report.title }}
                    </span>
                  </h3>
                  <div class="">
                    <van-field
                      v-model="report.description"
                      readonly
                      rows="2"
                      type="textarea"
                      maxlength="500"
                      placeholder="Message"
                      show-word-limit
                    />
                  </div>
                </div>
              </template>
            </van-cell>
          </van-list>
        </template>
      </van-collapse-item>
    </van-collapse>

    <div class="border-2 border-red-400 rounded m-2 px-2 py-1 bg-red-100" v-else>
      Kamu offline. Tidak dapat menampilkan data
    </div>
  </div>
</template>
<script>
  import dayjs from "dayjs";
  import axios from "@/plugins/axios";

  import { online } from "@/states/local";

  export default {
    data() {
      return {
        reports: [],
        online,
        activeAccordion: []
      };
    },
    components: {},
    computed: {
      dailyReports() {
        return this.sortReportsByTimestamp();
      }
    },
    mounted() {
      axios
        .get("/report/daily/@me")
        .then((res) => {
          const attendances = res.data;
          this.reports = attendances.map((x) => ({
            id: x.id,
            title: x.reportTitle,
            description: x.reportContent,
            images: x.reportImages,
            labels: x.reportImageLabels,
            timestamp: x.createdAt
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    },
    methods: {
      sortReportsByTimestamp() {
        let dailyReport = [];
        this.reports
          .sort((a, b) => new Date(b.timestamp).valueOf() - new Date(a.timestamp).valueOf())
          .forEach((report) => {
            const reportDate = dayjs(report.timestamp).format("YYYY-MM-DD");
            const existingReport = dailyReport.find((r) => r.date === reportDate);
            if (existingReport) {
              existingReport.reports.push(report);
            } else {
              dailyReport.push({ date: reportDate, reports: [report] });
            }
          });
        return dailyReport;
      },
      iconParse(date) {
        const day = dayjs(date).format("HH:mm");
        return day;
      }
    }
  };
</script>
