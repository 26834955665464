import { reactive } from "vue";
import instance from "@/plugins/axios";

export const location = reactive({
  latitude: null,
  longitude: null,
  accuracy: null,
  error: null,
  isMocked: false
});

export const sendLocation = async () => {
  try {
    const response = await instance.post("/user/@me/telemetry", {
      latitude: location.latitude,
      longitude: location.longitude,
      accuracy: location.accuracy,
      isMocked: location.isMocked
    });
    return response;
  } catch (error) {
    console.error("Error sending location:", error);
    return null;
  }
};

export const getLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        location.latitude = position.coords?.latitude;
        location.longitude = position.coords?.longitude;
        location.accuracy = position.coords?.accuracy;
        location.error = null;

        try {
          const mocked = window.Android?.isLocationMock() || false;
          location.isMocked = mocked;
          // sendLocation();
        } catch (error) {
          console.error("Error getting mock location status:", error);
        }
      },
      (error) => {
        location.error = error.message;
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  }
};

export const watchLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.watchPosition(
      (position) => {
        console.log("watching location", position);

        location.latitude = position.coords?.latitude;
        location.longitude = position.coords?.longitude;
        location.accuracy = position.coords?.accuracy;
        location.error = null;

        try {
          const mocked = window.Android?.isLocationMock() || false;
          location.isMocked = mocked;
          sendLocation();
        } catch (error) {
          console.error("Error getting mock location status:", error);
        }
      },
      (error) => {
        location.error = error.message;
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  }
};
