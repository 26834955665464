<template>
  <div>
    <div class="relative bg-black text-white">
      <div v-if="message" class="absolute py-6 bg-red-500 top-0 left-0 w-full text-white text-center p-2">
        <p>{{ message }}</p>
      </div>
      <video else playsinline autoplay ref="cameraElement"></video>
      <div class="absolute bottom-0 w-full flex p-2">
        <button @click="switchCamera()" class="text-white text-center w-full">
          <v-icon icon="ph:camera-rotate" class="text-5xl text-white mx-auto" />
          <p>Switch Cam.</p>
        </button>

        <button @click="takePhoto()" class="text-white text-center w-full">
          <v-icon icon="ph:camera" class="text-5xl mx-auto" />
          <p>Ambil</p>
        </button>
        <button @click="switchFlash()" class="text-white text-center w-full">
          <v-icon icon="ph:flashlight" class="text-5xl mx-auto" />
          <p>Light</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import CameraHandler from "@/plugins/CameraHandler";

  export default {
    data() {
      return {
        cameraElement: null,
        camera: null,
        cameraSelector: 0,
        devices: [],
        message: null
      };
    },
    emits: ["photo"],
    async mounted() {
      this.camera = new CameraHandler(this.$refs.cameraElement, 720, 720);

      await this.camera.start().catch((err) => {
        this.message = err;
      });
      await this.camera
        .getDevices()
        .then((devices) => {
          this.devices = devices;
        })
        .catch((err) => {
          this.message = err;
        });
    },
    beforeUnmount() {
      this.camera.stop();
      this.message = null;
      console.log("Closed Camera");
    },
    beforeDestroy() {
      this.camera.stop();
      this.message = null;
      console.log("Closed Camera");
    },
    methods: {
      takePhoto() {
        this.camera.capture().then((photo) => {
          this.$emit("photo", photo);
        });
      },
      async switchCamera() {
        this.cameraSelector = ++this.cameraSelector % this.devices.length;
        await this.camera.switchCamera(this.devices[this.cameraSelector]?.id);
      },
      async switchFlash() {
        await this.camera
          .toggleTorch()
          .then((res) => {
            this.message = res;
          })
          .catch((err) => {
            this.message = err;
            console.error(err);
          });
      }
    }
  };
</script>
