<template>
  <div class="w-full overflow-hidden rounded-t">
    <button @click="toggle = !toggle" class="w-full px-2 py-2 border-b border-primary text-left">
      <slot name="title"></slot>
      <v-icon :class="{ 'rotate-180': toggle }" icon="ph:caret-down" class="duration-300 text-2xl float-right" />
    </button>
    <div v-show="toggle" class="overflow-hidden rounded-b">
      <slot></slot>
    </div>
  </div>
</template>
<script>
  export default {
    name: "DropDown",
    data() {
      return {
        toggle: false
      };
    }
  };
</script>
