<template>
  <div class="relative">
    <div class="overflow-hidden rounded my-4 mx-2 flex shadow">
      <div class="w-2" :class="dateRange.start || dateRange.end ? 'bg-green-400' : 'bg-gray-400'"></div>
      <div class="px-4 py-2 w-full text-left">
        <button
          v-if="dateRange.start || dateRange.end"
          @click="
            dateRange.start = null;
            dateRange.end = null;
            calendar = !calendar;
          "
          class="bg-danger float-right text-white px-2 py-1 rounded"
        >
          <v-icon icon="ph:trash" class="text-lg inline" />
          <span> Hapus </span>
        </button>
        <h4 class="text-xl font-bold">{{ title || "Pilih jangkauan tanggal" }}</h4>
        <div class="flex my-2 gap-2 max-[420px]:flex-col">
          <vc-date-picker :key="calendar" mode="date" class="mx-auto" is-range v-model="dateRange" />
          <div class="w-full">
            <p class="text-sm">Tanggal awal</p>
            <p class="text-lg font-bold">{{ dateRange.start ? formatDate(dateRange.start) : "-" }}</p>
            <p class="text-sm">Tanggal akhir</p>
            <p class="text-lg font-bold">{{ dateRange.start ? formatDate(dateRange.end) : "-" }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import dayjs from "dayjs";

  export default {
    components: {},
    data() {
      return {
        calendar: false,
        dateRange: {
          start: null,
          end: null
        }
      };
    },
    props: {
      title: String
    },
    emits: ["selected"],
    watch: {
      picture: {
        handler(val) {
          this.$emit("selected", val);
        },
        immediate: true,
        deep: true
      }
    },
    computed: {},
    methods: {
      formatDate(date) {
        return dayjs(date).format("ddd, DD MMMM YYYY");
      }
    }
  };
</script>
