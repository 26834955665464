var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative pb-48"},[_c('van-popup',{attrs:{"round":"","position":"bottom"},model:{value:(_vm.toggle.date),callback:function ($$v) {_vm.$set(_vm.toggle, "date", $$v)},expression:"toggle.date"}},[_c('van-datetime-picker',{attrs:{"type":"datehour","title":"Tanggal dan Jam","min-date":_vm.options.minDate,"max-date":_vm.options.maxDate,"confirm-button-text":"Pilih","cancel-button-text":"Batal","formatter":_vm.pickerFormat},on:{"confirm":(e) => {
          _vm.toggle.date = false;
          _vm.date = e;
        },"cancel":() => (_vm.toggle.date = false)}})],1),_c('van-popup',{attrs:{"round":"","position":"bottom"},model:{value:(_vm.toggle.shift),callback:function ($$v) {_vm.$set(_vm.toggle, "shift", $$v)},expression:"toggle.shift"}},[_c('van-picker',{attrs:{"title":"Shift","confirm-button-text":"Pilih","cancel-button-text":"Batal","show-toolbar":"","columns":[
        {
          text: 'Shift 1 (Pagi)',
          value: 0
        },
        {
          text: 'Shift 2 (Siang)',
          value: 1
        },
        {
          text: 'Shift 3 (Sore)',
          value: 2
        }
      ]},on:{"cancel":function($event){_vm.toggle.shift = false},"confirm":(e) => {
          _vm.shift = e;
          _vm.toggle.shift = false;
        }}})],1),_c('div',{staticClass:"relative"},[_c('div',{},[_c('van-cell-group',{attrs:{"inset":"","title":"Pilih Waktu Overtime"}},[_c('van-field',{attrs:{"readonly":"","clickable":"","label":"Waktu Mulai","value":_vm.date ? _vm.formatDate(_vm.date, 'DD MMM, YYYY HH:mm') : null,"placeholder":"Pilih waktu"},on:{"click":() => (_vm.toggle.date = true)}}),_c('van-field',{attrs:{"readonly":"","clickable":"","label":"Shift","value":_vm.shift?.text,"placeholder":"Pilih Shift"},on:{"click":() => (_vm.toggle.shift = true)}}),_c('van-cell',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("Total Jam")])]},proxy:true},{key:"right-icon",fn:function(){return [_c('van-stepper',{attrs:{"min":"1","max":"5","integer":"","show-plus":"","show-minus":""},model:{value:(_vm.end),callback:function ($$v) {_vm.end=$$v},expression:"end"}})]},proxy:true}])})],1)],1)]),_c('TextBoxForm',{attrs:{"title":"Keterangan"},on:{"selected":(a) => (_vm.keterangan = a)}}),_c('div',{staticClass:"fixed max-w-xl w-full bottom-0 bg-white px-2 py-4 flex items-end shadow-md shadow-primary"},[_c('div',{staticClass:"w-full pr-2"},[_vm._m(0),(!_vm.allDone)?_c('p',{staticClass:"text-sm text-danger"},[_vm._v("*Harap isi semua untuk mengirim")]):_vm._e()]),_c('van-button',{staticClass:"whitespace-nowrap",attrs:{"disabled":!_vm.allDone,"loading":_vm.isLoading,"type":"info","loading-type":"spinner"},on:{"click":_vm.submit}},[_c('v-icon',{staticClass:"text-2xl mr-1 inline",attrs:{"icon":"material-symbols:send"}}),_c('span',{staticClass:"ml-2 font-bold"},[_vm._v("Simpan")])],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2"},[_c('p',[_vm._v("Jika sudah. Tekan \"simpan\"")])])
}]

export { render, staticRenderFns }