<template>
  <div class="relative pb-48">
    <PictureForm />
    <CheckListForm />
    <LocationForm />
    <DateRangeForm />
    <TextBoxForm />
    <div class="sticky bottom-0 bg-white px-2 py-4 flex shadow-md shadow-primary">
      <div class="w-full pr-2">
        <p class="text-sm text-danger">*Harap isi semua untuk mengirim</p>
      </div>
      <button
        :disabled="!allDone"
        class="bg-primary disabled:bg-opacity-50 whitespace-nowrap text-white px-4 py-2 font-bold rounded"
      >
        <v-icon icon="material-symbols:send" class="text-2xl mr-1 inline" />
        Kirim
      </button>
    </div>
  </div>
</template>

<script>
  import DateRangeForm from "@/components/Forms/DateRangeForm.vue";
  import LocationForm from "@/components/Forms/LocationForm.vue";
  import PictureForm from "@/components/Forms/PictureForm.vue";
  import TextBoxForm from "@/components/Forms/TextBoxForm.vue";
  import CheckListForm from "@/components/Forms/CheckListForm.vue";

  export default {
    name: "CleaningReportNewPage",
    components: {
      PictureForm,
      LocationForm,
      TextBoxForm,
      DateRangeForm,
      CheckListForm
    },
    data() {
      return {};
    },
    computed: {
      allDone() {
        return false;
      }
    },
    methods: {}
  };
</script>
