<template>
  <div class="relative">
    <van-cell-group inset :title="title || 'Masukan Deskripsi'">
      <van-cell>
        <template>
          <van-field
            v-model="description"
            rows="3"
            autosize
            type="textarea"
            maxlength="1024"
            placeholder="Deskripsi / Keterangan"
            show-word-limit
          />
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
  export default {
    props: {
      title: String
    },
    data() {
      return {
        description: ""
      };
    },
    emits: ["selected"],
    watch: {
      description: {
        handler(val) {
          this.$emit("selected", val);
        },
        immediate: true
      }
    }
  };
</script>
