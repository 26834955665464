<template>
  <div class="relative">
    <van-popup v-model="toggle" round position="bottom">
      <button
        @click="() => (toggle = false)"
        class="absolute top-2 right-2 p-2 z-50 bg-red-50 rounded-full text-nowrap border-2 text-red-400 border-red-400"
      >
        <v-icon icon="ph:x" class="text-2xl" />
      </button>
      <MapComp :points="points" @selected="pointSelected" />
    </van-popup>

    <van-cell-group inset :title="title || 'Pilih lokasi'">
      <template v-if="doPointsExist">
        <van-cell
          title="Buka Peta Lokasi"
          @click="() => (toggle = true)"
          is-link
          label="Tekan untuk mengambil lokasi"
        />
        <van-cell
          v-if="point"
          icon="location-o"
          :title="point ? point.name : 'Tidak ada (Tekan untuk menambahkan)'"
          :label="point.distance ? `${point.distance.toFixed(2)} meter away from current location` : ''"
        />
      </template>

      <van-cell v-else icon="location-o" title="Lokasi Belum Ditambah" label="Harap hubungi admin!" />
    </van-cell-group>
  </div>
</template>

<script>
  import MapComp from "@/components/Inputs/MapComp.vue";
  import { pointLocations, fetchPointLocations } from "@/states/user";

  export default {
    components: {
      MapComp
    },
    props: {
      title: String
    },
    data() {
      return {
        toggle: false,
        point: null,
        pointLocations
      };
    },
    mounted() {
      fetchPointLocations();
    },

    computed: {
      doPointsExist() {
        return this.points.length > 0;
      },
      points() {
        return this.pointLocations.data.map((x) => ({
          id: x.id,
          name: x.name,
          coords: [x.latitude, x.longitude],
          radius: x.radius
        }));
      }
    },

    emits: ["selected"],
    watch: {
      point: {
        handler(val) {
          this.$emit("selected", val);
        },
        immediate: true,
        deep: true
      }
    },
    methods: {
      takePhoto(photo) {
        const url = URL.createObjectURL(photo);
        this.pictures.push(url);
      },
      pointSelected(point) {
        this.point = point;
        setTimeout(() => {
          this.toggle = false;
        }, 100);
        // this.toggle = false;
      }
    }
  };
</script>
