<template>
  <div class="min-w-96 w-full h-full" v-if="payroll.payroll.length > 0">
    '">
    <div class="px-4 py-4">
      <div class="text-right float-right">
        <h1 class="font-bold text-2xl">Slip Gaji</h1>
        <p class="text-sm">{{ datePeriod }}</p>
      </div>
      <div class="">
        <h1 class="font-bold text-xl border-bottom">{{ payroll.company.name }}</h1>
        <p class="italic mt-1">{{ payroll.company.address }}</p>
      </div>
      <div class="mt-4">
        <h2 class="font-bold text-lg">Payroll Karyawan</h2>
        <div class="">
          <table>
            <tr>
              <td class="opacity-75 text-sm pr-2">Nama:</td>
              <td>{{ payroll.employee.name }}</td>
            </tr>
            <tr>
              <td class="opacity-75 text-sm pr-2">NIK:</td>
              <td>{{ payroll.employee.employeeId }}</td>
            </tr>
            <tr>
              <td class="opacity-75 text-sm pr-2">Posisi / Jabatan:</td>
              <td>{{ payroll.employee.position }}</td>
            </tr>
            <tr>
              <td class="opacity-75 text-sm pr-2">Mulai Bekerja:</td>
              <td>{{ startWorkDate }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div>
        <h2 class="font-bold text-lg mt-4">Detail Gaji</h2>
        <div class="mt-2">
          <table>
            <tr>
              <td class="opacity-75 text-sm pr-2">Periode:</td>
              <td>{{ datePeriod }}</td>
            </tr>
          </table>
        </div>
        <div class="mt-4">
          <!-- Earnings -->
          <table class="w-full">
            <tr class="border-b">
              <th class="text-left">Pendapatan</th>
              <th class="text-right">Jumlah</th>
            </tr>
            <tr v-for="item in additions" :key="item.key">
              <td class="text-left">{{ item.key }}</td>
              <td class="text-right">{{ currency(item.value) }}</td>
            </tr>
          </table>
          <!-- Deductions -->
          <table class="w-full mt-2">
            <tr class="border-b">
              <th class="text-left">Potongan</th>
              <th class="text-right"></th>
            </tr>
            <tr v-for="item in deductions" :key="item.key">
              <td class="text-left">{{ item.key }}</td>
              <td class="text-right">{{ currency(item.value) }}</td>
            </tr>
          </table>
          <!-- Total Earnings -->
          <table class="w-full mt-4 border-t-2 border-t-black">
            <tr>
              <td class="text-left">Total Pendapatan</td>
              <td class="text-right font-bold">{{ currency(totalEarnings) }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- Empty / non existent error -->
  <div v-else>
    <div class="flex justify-center items-center h-full py-16">
      <div class="text-center">
        <v-icon icon="ph:file-x" class="text-6xl mx-auto text-primary" />
        <h1 class="font-bold text-2xl">Data Tidak Ditemukan</h1>
        <p class="text-sm">Data slip gaji untuk periode ini tidak ditemukan</p>
      </div>
    </div>
  </div>
</template>

<script>
  import dayjs from "dayjs";
  import instance from "@/plugins/axios";

  const currency = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR"
  });

  export default {
    name: "PayrollComponent",
    data() {
      return {
        payroll: {
          company: {
            name: "",
            address: ""
          },
          employee: {
            name: "",
            employeeId: "",
            position: "",
            startWork: ""
          },
          period: "",
          payroll: []
        }
      };
    },
    props: {
      date: {
        type: Date,
        required: true
      }
    },
    mounted() {
      instance
        .get("/user/@me/slip/" + dayjs(this.date).format("YYYY-MM"))
        .then((res) => {
          this.payroll = {
            company: {
              name: res.data?.workSiteName || "PT. ..."
              // address: "Jl. Jendral Sudirman No. 1"
            },
            employee: {
              name: res.data?.fullName || "<Empty>",
              employeeId: res.data?.username || "<Empty>",
              position: res.data?.occupationName || "<Empty>",
              startWork: new Date(res.data?.joinDate)
            },
            period: new Date(res.data?.period),
            payroll: res.data?.slip || []
          };
        })
        .catch((err) => {
          console.error(err);
        });
    },

    // const totalBruto = computed(() => {
    //   return data.value.filter((item) => item.type == "debit").reduce((acc, curr) => acc + curr.value, 0);
    // });

    // const totalPotongan = computed(() => {
    //   return data.value.filter((item) => item.type == "credit").reduce((acc, curr) => acc + curr.value, 0);
    // });

    // const getOnlyPotongan = computed(() => {
    //   return data.value.filter((item) => item.type == "credit");
    // });

    // const getOnlyGaji = computed(() => {
    //   return data.value.filter((item) => item.type == "debit");
    // });

    // const gajiDiterima = computed(() => {
    //   return totalBruto.value - totalPotongan.value;
    // });
    computed: {
      datePeriod() {
        return dayjs(this.payroll.period).format("MMMM YYYY");
      },
      startWorkDate() {
        return dayjs(this.payroll.employee.startWork).format("DD MMMM YYYY");
      },
      additions() {
        return this.payroll.payroll.filter((item) => item.type === "debit");
      },
      deductions() {
        return this.payroll.payroll.filter((item) => item.type === "credit");
      },
      totalDeductions() {
        return this.deductions.reduce((acc, item) => acc + item.value, 0);
      },
      totalEarnings() {
        return this.payroll.payroll.reduce((acc, item) => acc + (item.type == "credit" ? -item.value : item.value), 0);
      }
    },
    methods: {
      currency(value) {
        return currency.format(value);
      }
    }
  };
</script>
