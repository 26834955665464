import axios from "axios";
import { Toast } from "vant";

const instance = axios.create({
  //"https://demo-api.kyuiki.com",
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: localStorage.getItem("token")
  }
});

export default instance;

export function deleteUpload(images) {
  return new Promise((resolve, reject) => {
    axios
      .delete(process.env.VUE_APP_API_UPLOAD + "/attachment", {
        data: images,
        headers: {
          Authorization: localStorage.getItem("token")
        }
      })
      .then((response) => {
        Toast.success({
          message: "Foto berhasil dihapus",
          duration: 5000
        });
        resolve(response);
      })
      .catch((error) => {
        Toast.fail({
          message: "Gagal menghapus foto",
          duration: 5000
        });
        reject(error);
      });
  });
}
export function fileUpload(blob) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("image", blob);
    axios
      .post(process.env.VUE_APP_API_UPLOAD + "/attachment", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token")
        }
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
