<template>
  <div class="">
    <div class="mt-4" v-if="has.includes('DAILY')">
      <div class="border-b-2 px-2 py-1 border-primary">
        <h2 class="font-bold text-xl">Laporan Harian</h2>
        <p class="text-md">Laporan Harian</p>
      </div>
      <div class="flex gap-1 max-[542px]:flex-col mt-2 px-2">
        <router-link to="/dashboard/report/daily" class="block bg-primary text-white p-2 rounded w-full">
          <div class="flex justify-between">
            <v-icon icon="ph:list-checks" class="text-4xl my-auto mr-2" />
            <div class="w-full">
              <p class="font-bold">Lihat Laporan</p>
              <p class="text-sm">Lihat laporan harian sebelumnya</p>
            </div>
            <v-icon icon="material-symbols:chevron-right" class="text-2xl" />
          </div>
        </router-link>
        <router-link to="/dashboard/report/daily/new" class="block bg-primary text-white p-2 rounded w-full">
          <div class="flex justify-between">
            <v-icon icon="ph:list-plus" class="text-4xl my-auto mr-2" />
            <div class="w-full">
              <p class="font-bold">Buat baru</p>
              <p class="text-sm">Buat laporan harian baru</p>
            </div>
            <v-icon icon="material-symbols:chevron-right" class="text-2xl" />
          </div>
        </router-link>
      </div>
    </div>
    <div class="mt-4" v-if="has.includes('PATROL')">
      <div class="border-b-2 px-2 py-1 border-primary">
        <h2 class="font-bold text-xl">Laporan Patroli</h2>
        <p class="text-md">Laporan Patroli</p>
      </div>
      <div class="flex gap-1 max-[542px]:flex-col mt-2 px-2">
        <router-link to="/dashboard/report/patrol" class="block bg-primary text-white p-2 rounded w-full">
          <div class="flex justify-between">
            <v-icon icon="ph:list-checks" class="text-4xl my-auto mr-2" />
            <div class="w-full">
              <p class="font-bold">Lihat Laporan</p>
              <p class="text-sm">Lihat laporan patroli sebelumnya</p>
            </div>
            <v-icon icon="material-symbols:chevron-right" class="text-2xl" />
          </div>
        </router-link>
        <router-link to="/dashboard/report/patrol/new" class="block bg-primary text-white p-2 rounded w-full">
          <div class="flex justify-between">
            <v-icon icon="ph:list-plus" class="text-4xl my-auto mr-2" />
            <div class="w-full">
              <p class="font-bold">Buat baru</p>
              <p class="text-sm">Buat laporan patroli baru</p>
            </div>
            <v-icon icon="material-symbols:chevron-right" class="text-2xl" />
          </div>
        </router-link>
      </div>
    </div>
    <div class="mt-4" v-if="has.includes('CLEANING')">
      <div class="border-b-2 px-2 py-1 border-primary">
        <h2 class="font-bold text-xl">Checklist Kebersihan</h2>
        <p class="text-md">Laporan Checklist Kebersihan</p>
      </div>
      <div class="flex gap-1 max-[542px]:flex-col mt-2 px-2">
        <router-link to="/dashboard/report/cleaning" class="block bg-primary text-white p-2 rounded w-full">
          <div class="flex justify-between">
            <v-icon icon="ph:list-checks" class="text-4xl my-auto mr-2" />
            <div class="w-full">
              <p class="font-bold">Lihat Laporan</p>
              <p class="text-sm">Lihat Checklist Kebersihan sebelumnya</p>
            </div>
            <v-icon icon="material-symbols:chevron-right" class="text-2xl" />
          </div>
        </router-link>
        <router-link to="/dashboard/report/cleaning/new" class="block bg-primary text-white p-2 rounded w-full">
          <div class="flex justify-between">
            <v-icon icon="ph:list-plus" class="text-4xl my-auto mr-2" />
            <div class="w-full">
              <p class="font-bold">Buat baru</p>
              <p class="text-sm">Buat Checklist Kebersihan baru</p>
            </div>
            <v-icon icon="material-symbols:chevron-right" class="text-2xl" />
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ReportPage",
    data() {
      return {
        isOpen: false,
        has: ["PATROL", "DAILY"]
      };
    },
    props: {
      items: Array
    },
    emits: ["selected"]
  };
</script>
