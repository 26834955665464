<template>
  <div class="py-4">
    <div class="p-2">
      <router-link to="/dashboard/attendance/new" class="bg-primary px-4 py-2 rounded flex float-right">
        <v-icon icon="material-symbols:add" class="text-2xl text-white mr-2" />
        <span class="text-white">Absen</span>
      </router-link>
      <h1 class="text-2xl font-bold">Absensi</h1>
      <p>Absensi yang telah dibuat oleh pengguna.</p>
    </div>
    <van-collapse v-model="activeAccordion" v-if="online">
      <van-collapse-item v-for="date in dailyReports" :key="date.date">
        <template #title>
          <span class="text-left">{{ date.date }}</span>
        </template>
        <template #default>
          <van-list>
            <van-cell v-for="report in date.reports" :key="report.id">
              <template>
                <div class="w-full">
                  <h3>
                    <van-tag type="primary">
                      <p>{{ iconParse(report.timestamp) }}</p>
                    </van-tag>
                    <span class="text-lg font-semibold">
                      {{ report.title }}
                    </span>
                  </h3>
                  <van-steps
                    :active-color="report.isOverdue ? '#ff4400' : '#3aae47'"
                    :active="!report.description[1] || report.description[1] == 'Invalid Date' ? 0 : 1"
                    direction="vertical"
                  >
                    <van-step>
                      <img
                        v-for="(c, i) in report.inImages"
                        :key="i"
                        :src="c"
                        alt=""
                        v-lazy="c"
                        class="w-12 aspect-square object-cover float-right ml-2"
                      />
                      <h4 class="font-bold">Check-in</h4>
                      <p class="text-sm">{{ report.description[0] }}</p>
                    </van-step>
                    <van-step>
                      <template v-if="!report.isOverdue">
                        <img
                          v-for="(c, i) in report.outImages"
                          :key="i"
                          :src="c"
                          alt=""
                          class="w-12 aspect-square object-cover float-right ml-2"
                        />
                      </template>
                      <span v-else class="float-right font-bold">Lupa Checkout!</span>
                      <h4 class="font-bold">Check-out</h4>
                      <p class="text-sm">
                        {{
                          !report.description[1] || report.description[1] == "Invalid Date" ? "" : report.description[1]
                        }}
                      </p>
                    </van-step>
                  </van-steps>
                  <div class="flex justify-between w-full gap-4"></div>
                </div>
              </template>
            </van-cell>
          </van-list>
        </template>
      </van-collapse-item>
    </van-collapse>
    <div class="border-2 border-red-400 rounded m-2 px-2 py-1 bg-red-100" v-else>
      Kamu offline. Tidak dapat menampilkan data
    </div>
  </div>
</template>
<script>
  // import DropDown from "@/components/DropDown.vue";
  import dayjs from "dayjs";
  import axios from "@/plugins/axios";
  import { online } from "@/states/local";

  export default {
    data() {
      return {
        reports: [
          // {
          //   id: 12,
          //   title: "Daily Report",
          //   description: "Generate daily report.",
          //   timestamp: new Date().valueOf()
          // }
        ],
        online,
        activeAccordion: ["0"]
      };
    },
    components: {
      // DropDown
    },
    computed: {
      dailyReports() {
        return this.sortReportsByTimestamp();
      }
    },
    mounted() {
      axios
        .get("/attendance/@me")
        .then((res) => {
          const attendances = res.data;
          this.reports = attendances.map((x) => ({
            id: x.id,
            title: "Absensi",
            description: [
              dayjs(x.checkIn).format("DD MMM, HH:mm"),
              x.checkOut != "Invalid Date" ? dayjs(x.checkOut).format("DD MMM, HH:mm") : null
            ],
            isOverdue: x.isOverdue,
            inImages: x.checkInImages,
            outImages: x.checkOutImages,
            timestamp: x.checkIn
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    },
    methods: {
      sortReportsByTimestamp() {
        let dailyReport = [];
        this.reports
          .sort((a, b) => new Date(b.timestamp).valueOf() - new Date(a.timestamp).valueOf())
          .forEach((report) => {
            const reportDate = dayjs(report.timestamp).format("YYYY-MM-DD");
            const existingReport = dailyReport.find((r) => r.date === reportDate);
            if (existingReport) {
              existingReport.reports.push(report);
            } else {
              dailyReport.push({ date: reportDate, reports: [report] });
            }
          });
        return dailyReport;
      },
      iconParse(date) {
        const day = dayjs(date).format("HH:mm");
        return day;
      }
    }
  };
</script>
