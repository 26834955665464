import instance from "@/plugins/axios";
import { reactive } from "vue";
import { Notify } from "vant";
import router from "@/router";

export const users = reactive({
  data: {},
  loading: false,
  error: null
});

export const pointLocations = reactive({
  data: [],
  loading: false,
  error: null
});

export const fetchUsers = async () => {
  return new Promise((resolve, reject) => {
    users.loading = true;
    instance
      .get("/user/@me")
      .then((res) => {
        users.data = res.data;
        users.error = null;
        users.loading = false;
        resolve(res.data);
      })
      .catch((err) => {
        users.error = err;
        users.loading = false;

        // error 401
        if (err.response?.status == 401) {
          localStorage.removeItem("token");
          Notify({ type: "danger", message: "Session expired, please login again" });
          router.push("/");
        }
        reject(err);
      });
  });
};

export const fetchPointLocations = async () => {
  return new Promise((resolve, reject) => {
    instance
      .get("/location/@me/list")
      .then((res) => {
        pointLocations.data = res.data;
        pointLocations.error = null;
        pointLocations.loading = false;
        resolve(res.data);
      })
      .catch((err) => {
        pointLocations.error = err;
        pointLocations.loading = false;
        reject(err);
      });
  });
};
