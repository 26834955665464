<template>
  <div class="relative pb-48">
    <van-popup v-model="toggle.date" round position="bottom">
      <van-datetime-picker
        type="datehour"
        title="Tanggal dan Jam"
        :min-date="options.minDate"
        :max-date="options.maxDate"
        confirm-button-text="Pilih"
        cancel-button-text="Batal"
        :formatter="pickerFormat"
        @confirm="
          (e) => {
            toggle.date = false;
            date = e;
          }
        "
        @cancel="() => (toggle.date = false)"
      />
    </van-popup>
    <van-popup v-model="toggle.shift" round position="bottom">
      <van-picker
        title="Shift"
        confirm-button-text="Pilih"
        cancel-button-text="Batal"
        show-toolbar
        :columns="[
          {
            text: 'Shift 1 (Pagi)',
            value: 0
          },
          {
            text: 'Shift 2 (Siang)',
            value: 1
          },
          {
            text: 'Shift 3 (Sore)',
            value: 2
          }
        ]"
        @cancel="toggle.shift = false"
        @confirm="
          (e) => {
            shift = e;
            toggle.shift = false;
          }
        "
      />
    </van-popup>
    <div class="relative">
      <div class="">
        <van-cell-group inset title="Pilih Waktu Overtime">
          <van-field
            readonly
            clickable
            label="Waktu Mulai"
            :value="date ? formatDate(date, 'DD MMM, YYYY HH:mm') : null"
            placeholder="Pilih waktu"
            @click="() => (toggle.date = true)"
          />
          <van-field
            readonly
            clickable
            label="Shift"
            :value="shift?.text"
            placeholder="Pilih Shift"
            @click="() => (toggle.shift = true)"
          />
          <van-cell>
            <template #title>
              <span>Total Jam</span>
            </template>
            <template #right-icon>
              <van-stepper v-model="end" min="1" max="5" integer show-plus show-minus />
            </template>
          </van-cell>
        </van-cell-group>
      </div>
    </div>
    <TextBoxForm title="Keterangan" @selected="(a) => (keterangan = a)" />

    <div class="fixed max-w-xl w-full bottom-0 bg-white px-2 py-4 flex items-end shadow-md shadow-primary">
      <div class="w-full pr-2">
        <div class="mb-2">
          <p>Jika sudah. Tekan "simpan"</p>
        </div>
        <p v-if="!allDone" class="text-sm text-danger">*Harap isi semua untuk mengirim</p>
      </div>
      <van-button
        :disabled="!allDone"
        :loading="isLoading"
        type="info"
        loading-type="spinner"
        @click="submit"
        class="whitespace-nowrap"
      >
        <v-icon icon="material-symbols:send" class="text-2xl mr-1 inline" />
        <span class="ml-2 font-bold">Simpan</span>
      </van-button>
    </div>
  </div>
</template>
<script>
  import TextBoxForm from "@/components/Forms/TextBoxForm.vue";
  import dayjs from "dayjs";

  import { location } from "@/states/location";

  import local from "@/states/local";
  import { users } from "@/states/user";

  export default {
    name: "OvertimeNewPage",
    components: {
      TextBoxForm
    },
    data() {
      return {
        options: {
          minDate: new Date(),
          maxDate: new Date(new Date().valueOf() + 30 * 24 * 60 * 60 * 1000)
        },
        check: null,
        isLoading: false,
        tracking: location,
        keterangan: null,
        date: null,
        toggle: {
          date: false,
          shift: false
        },
        shift: null,
        end: 1,
        users,
        shifts: null,
        local
      };
    },
    async mounted() {},
    computed: {
      allDone() {
        return this.keterangan;
      }
    },
    methods: {
      async submit() {
        const toasty = this.$toast.loading({ message: "Mengirim...", forbidClick: true, duration: 0 });
        this.isLoading = true;

        const coordCurrently = this.tracking;

        local
          .processData("/overtime/assign", {
            groupId: this.users.data.workSite,
            currentLocation: [coordCurrently.latitude, coordCurrently.longitude],
            reason: this.keterangan,
            date: this.date,
            shift: this.shift.value,
            end: new Date(new Date(this.date).valueOf() + this.end * 60 * 60 * 1000)
          })
          .then(() => {
            this.isLoading = false;
            toasty.clear();
            this.$router.go(-1);
          })
          .catch((err) => {
            console.error(err);
            toasty.clear();
            this.$toast.fail({ message: "Gagal mengirim data", duration: 5000 });
            this.isLoading = false;
          });
      },
      formatDate(date, format = "YYYY-MM-DD HH:mm") {
        return dayjs(date).format(format);
      },
      pickerFormat(type, val) {
        if (type === "hour") {
          return `${val % 12 || 12} ${val < 12 ? "AM" : "PM"}`;
        }
        return val;
      }
    }
  };
</script>
