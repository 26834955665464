import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/pages/HomePage.vue";
import About from "@/pages/AboutPage.vue";

import DashboardLayout from "@/layouts/DashboardLayout.vue";
import FeatLayout from "@/layouts/FeatureLayout.vue";
import Dashboard from "@/pages/DashboardPage.vue";
import BlankLayout from "@/layouts/BlankLayout.vue";
import AttendancePageVue from "@/pages/attendance/AttendancePage.vue";
import NewAttendancePageVue from "@/pages/attendance/NewAttendancePage.vue";
import OvertimePageVue from "@/pages/reports/Overtime/OvertimePage.vue";
import NewOvertimePageVue from "@/pages/reports/Overtime/NewOvertimePage.vue";
import ReportPageVue from "@/pages/reports/ReportPage.vue";
import DailyReportPageVue from "@/pages/reports/Daily/DailyReportPage.vue";
import PatrolReportPageVue from "@/pages/reports/Patrol/PatrolReportPage.vue";
import CleaningReportPageVue from "@/pages/reports/Cleaning/CleaningReportPage.vue";
import NewPatrolReportPageVue from "@/pages/reports/Patrol/NewPatrolReportPage.vue";
import NewDailyReportPageVue from "@/pages/reports/Daily/NewDailyReportPage.vue";
import NewCleaningReportPageVue from "@/pages/reports/Cleaning/NewCleaningReportPage.vue";
import PayrollPageVue from "@/pages/payroll/PayrollPage.vue";
import DevPageVue from "@/pages/DevPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: Dashboard
      },
      {
        path: "about",
        component: About
      }
    ]
  },
  {
    path: "/dashboard",
    component: BlankLayout,
    children: [
      {
        path: "attendance",
        component: FeatLayout,
        props: {
          title: "Attendance"
        },
        children: [
          {
            path: "",
            component: AttendancePageVue
          },
          {
            path: "monitor",
            component: () => import("@/pages/attendance/AttendanceMonitorPage.vue")
          },

          {
            path: "new",
            component: NewAttendancePageVue
          }
        ]
      },
      {
        path: "dev",
        component: FeatLayout,
        props: {
          title: "Developer Test"
        },
        children: [
          {
            path: "",
            component: DevPageVue
          }
        ]
      },
      {
        path: "overtime",
        component: FeatLayout,
        props: {
          title: "Report"
        },
        children: [
          {
            path: "",
            component: OvertimePageVue
          },
          {
            path: "new",
            component: NewOvertimePageVue
          }
        ]
      },
      {
        path: "report",
        component: FeatLayout,
        props: {
          title: "Report"
        },
        children: [
          {
            path: "",
            component: ReportPageVue
          },
          {
            path: "daily",
            component: DailyReportPageVue
          },
          {
            path: "patrol",
            component: PatrolReportPageVue
          },
          {
            path: "cleaning",
            component: CleaningReportPageVue
          },
          {
            path: "patrol/new",
            component: NewPatrolReportPageVue
          },
          {
            path: "daily/new",
            component: NewDailyReportPageVue
          },
          {
            path: "cleaning/new",
            component: NewCleaningReportPageVue
          }
        ]
      },
      {
        path: "payroll",
        component: FeatLayout,
        props: {
          title: "Payroll"
        },
        children: [
          {
            path: "",
            component: PayrollPageVue
          }
        ]
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// router.beforeEach((to, from, next) => {
// });

export default router;
