<template>
  <div class="relative pb-48">
    <PictureForm title="Ambil foto selfie" @selected="(a) => (fotoSelfie = a)" />
    <PictureForm title="Ambil foto sekitar" @selected="(a) => (fotoSekitar = a)" />
    <LocationForm title="Pilih lokasi Patroli" @selected="(a) => (lokasi = a?.id)" />
    <TextBoxForm title="Keterangan" @selected="(a) => (keterangan = a)" />

    <div class="fixed max-w-xl w-full bottom-0 bg-white px-2 py-4 flex items-end shadow-md shadow-primary">
      <div class="w-full pr-2">
        <div class="mb-2">
          <p>Jika sudah. Tekan "simpan"</p>
        </div>
        <p v-if="!allDone" class="text-sm text-danger">*Harap isi semua untuk mengirim</p>
      </div>
      <van-button
        :disabled="!allDone"
        :loading="isLoading"
        type="info"
        loading-type="spinner"
        @click="submit"
        class="whitespace-nowrap"
      >
        <v-icon icon="material-symbols:send" class="text-2xl mr-1 inline" />
        <span class="ml-2 font-bold">Simpan</span>
      </van-button>
    </div>
  </div>
</template>
<script>
  import LocationForm from "@/components/Forms/LocationForm.vue";
  import PictureForm from "@/components/Forms/PictureForm.vue";
  import TextBoxForm from "@/components/Forms/TextBoxForm.vue";
  // import instance from "@/plugins/axios";
  import { location } from "@/states/location";
  import local from "@/states/local";
  import { users } from "@/states/user";
  import { deleteUpload } from "@/plugins/axios";

  export default {
    name: "AttendancePage",
    components: {
      PictureForm,
      LocationForm,
      TextBoxForm
    },
    data() {
      return {
        check: null,
        lokasi: null,
        fotoSelfie: null,
        fotoSekitar: null,
        keterangan: null,
        users,
        isLoading: false,
        tracking: location,
        local
      };
    },
    async mounted() {},
    computed: {
      allDone() {
        return this.lokasi && this.fotoSelfie?.picture && this.fotoSekitar?.picture && this.keterangan;
      }
    },
    methods: {
      async submit() {
        const toasty = this.$toast.loading({ message: "Mengupload foto selfie...", forbidClick: true, duration: 0 });
        this.isLoading = true;
        const selfie = await local.processFile(this.fotoSelfie.picture).catch((err) => {
          console.error(err);
          toasty.clear();
          this.$toast.fail({ message: "Gagal mengupload foto selfie", duration: 5000 });
          this.isLoading = false;
        });
        if (!selfie) return;
        toasty.message = "Mengupload foto sekitar...";
        const sekitar = await local.processFile(this.fotoSekitar.picture).catch((err) => {
          console.error(err);
          toasty.clear();
          this.$toast.fail({ message: "Gagal mengupload foto sekitar", duration: 5000 });
          this.isLoading = false;
        });
        if (!sekitar) return;
        toasty.message = "Mengirim data...";

        const coordCurrently = this.tracking;
        console.log(coordCurrently, this.tracking);

        local
          .processData("/report/patrol/assign", {
            currentLocation: [coordCurrently.latitude, coordCurrently.longitude],
            images: [selfie, sekitar],
            locationId: this.lokasi,
            imageDescriptions: [this.fotoSelfie.description, this.fotoSekitar.description],
            description: this.keterangan,
            groupId: this.users.data.workSite
          })
          .then(() => {
            this.isLoading = false;
            toasty.message = "Selesai!";
            toasty.clear();
            this.$router.go(-1);
          })
          .catch((err) => {
            this.isLoading = false;
            toasty.message = "Gagal mengirim data. Menghapus foto...";
            deleteUpload([selfie, sekitar])
              .then(() => {
                toasty.clear();
                this.$toast.fail({ message: "Gagal mengirim. Coba lagi nanti...", duration: 5000 });
              })
              .catch(() => {});

            console.error(err);
          });
      }
    }
  };
</script>
