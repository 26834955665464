<template>
  <div class="relative">
    <div v-if="toggleCamera" class="z-50 fixed max-w-xl h-full top-12 w-full bg-white">
      <CameraComp
        @photo="
          (a) => {
            form[pictureForIndex].picture = a;
            toggleCamera = false;
          }
        "
      />
    </div>
    <div class="overflow-hidden rounded my-4 mx-2 flex shadow">
      <div class="w-2" :class="validation ? 'bg-green-400' : 'bg-gray-400'"></div>
      <div class="px-4 py-2 w-full text-left">
        <button
          v-if="description"
          @click="description = null"
          class="bg-danger float-right text-white px-2 py-1 rounded"
        >
          <v-icon icon="ph:trash" class="text-lg inline" />
          <span> Hapus </span>
        </button>
        <h4 class="text-xl font-bold">{{ title || "Checklist" }}</h4>
        <div class="flex my-2 gap-2 flex-col">
          <div v-for="(f, i) in form" :key="i" class="flex gap-2">
            <div class="w-full">
              <div class="flex mb-2">
                <input type="checkbox" v-model="f.checked" class="mr-2" />
                <input
                  type="text"
                  v-model="f.title"
                  placeholder="Nama Kegiatan"
                  class="border-b px-2 py-1 border-primary bg-primary bg-opacity-5 w-full"
                />
              </div>
              <textarea
                v-model="f.description"
                placeholder="Detail Dari Kegiatan"
                class="border-b px-2 py-1 border-primary bg-primary bg-opacity-5 w-full"
              />
            </div>
            <button
              v-if="!f.picture"
              @click="() => openCamera(i)"
              class="bg-primary bg-opacity-25 px-6 aspect-square flex-shrink-0 text-center"
            >
              <v-icon icon="ph:plus" class="text-3xl inline" />
              <p class="mt-2">Ambil</p>
            </button>
            <img v-else :src="blobURL(f.picture)" class="aspect-square flex-shrink-0 max-w-24 object-cover" />
          </div>
        </div>
        <div class="">
          <button @click="removeField" class="text-white px-2 py-1 rounded bg-danger mr-2">
            <v-icon icon="ph:trash" class="text-lg inline" />
            <span>Kurang</span>
          </button>
          <button @click="addField" class="text-white px-2 py-1 rounded bg-primary">
            <v-icon icon="ph:plus" class="text-lg inline" />
            <span>Tambah</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CameraComp from "../Inputs/CameraComp.vue";
  export default {
    props: {
      title: String
    },
    components: {
      CameraComp
    },
    data() {
      return {
        toggleCamera: false,
        template: {
          checked: false,
          title: "",
          description: "",
          picture: null
        },
        pictureForIndex: 0,
        form: [
          {
            checked: false,
            title: "",
            description: "",
            picture: null
          }
        ]
      };
    },
    emits: ["selected"],
    watch: {
      form: {
        handler(val) {
          this.$emit("selected", val);
        },
        immediate: true
      }
    },
    computed: {
      validation() {
        if (this.form.length < 1) return 0;
        const validate = this.form.reduce(
          (a, b) => a + ((b.title ? 1 : 0) + (b.description ? 1 : b.checked ? 1 : 0)) / 2,
          0
        );
        return Math.floor(validate / this.form.length);
      }
    },
    methods: {
      addField() {
        this.form.push(JSON.parse(JSON.stringify(this.template)));
      },
      removeField() {
        if (this.form.length < 2) return;
        this.form.pop();
      },
      openCamera(i) {
        this.toggleCamera = true;
        this.pictureForIndex = i;
      },
      blobURL(image) {
        return URL.createObjectURL(image);
      }
    }
  };
</script>
